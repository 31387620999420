define("discourse/plugins/discourse-zolidar/discourse/templates/connectors/user-profile-controls/zolidar-user-profile-controls", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    @href="https://zolidar.com/settings"
    @title="discourse_zolidar.profile.link.title"
    @label="discourse_zolidar.profile.link.label"
    @icon="user"
    class="btn-primary"
  />
  */
  {
    "id": "BSbhdtk5",
    "block": "[[[8,[39,0],[[24,0,\"btn-primary\"]],[[\"@href\",\"@title\",\"@label\",\"@icon\"],[\"https://zolidar.com/settings\",\"discourse_zolidar.profile.link.title\",\"discourse_zolidar.profile.link.label\",\"user\"]],null]],[],false,[\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-zolidar/discourse/templates/connectors/user-profile-controls/zolidar-user-profile-controls.hbs",
    "isStrictMode": false
  });
});