define("discourse/plugins/discourse-zolidar/discourse/initializers/discourse-zolidar-initializer", ["exports", "@ember/service", "discourse/lib/plugin-api", "discourse/lib/url"], function (_exports, _service, _pluginApi, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-zolidar-intializer",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.37.3", api => {
        api.modifyClass("route:users", {
          site: (0, _service.service)(),
          pluginId: "discourse-zolidar",
          beforeModel() {
            _url.default.routeTo(this.site.zolidar_redirects.users || "/");
          }
        });
        api.modifyClass("route:preferences.index", {
          pluginId: "discourse-zolidar",
          beforeModel() {
            this.router.transitionTo("preferences.emails");
          }
        });
        const site = api._lookupContainer("service:site");
        Object.keys(site.zolidar_redirects?.preferences || {}).forEach(path => {
          api.modifyClass(`route:preferences.${path}`, {
            site: (0, _service.service)(),
            pluginId: "discourse-zolidar",
            beforeModel() {
              const targetUrl = this.site.zolidar_redirects.preferences[path] || "/";
              _url.default.routeTo(targetUrl);
            }
          });
        });
        api.modifyClass("route:badges-show", {
          pluginId: "discourse-zolidar",
          beforeModel() {
            this.router.transitionTo("badges.index");
          }
        });
      });
    }
  };
});